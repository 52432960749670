import React, { useState, useEffect } from "react";
import "./Profile.css";
import { getCheaterData } from "./data";
import { secrecyMap } from "./util";
import { getProfile } from "./supabase";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const getId = () => {
    const url = window.location;
    const parts = url.href.split('/');
    const id = parts[parts.length - 1];
    console.log(id);
    return id;
}

const Profile = () => {
    const [profile, setCheater] = useState({photos: [], priority: []});
    const [loading, setLoading] = useState(true); // State to track loading state
    const [error, setError] = useState(null); // State to track errors

    const renderError = () => {
        if (error) {
            return <div>Eroare!</div>
        }
    }

    const renderLoading = () => {
        if (loading) {
            return <div>loading</div>
        }
    }

    const renderProfile = () => {
        if (profile) {
            return (
              <div className="profile-container mt-5 mb-5 mx-auto" style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px', width: '80%' }}>
                <h2 className="text-center">{profile.first_name} {profile.last_name}</h2>

                <Carousel showThumbs={false} >
                    {profile.photos.map((photo, index) => (
                        <img key={index} src={photo} alt={`Fotografie ${index + 1}`} style={{ width: 'auto', height: '50vh', objectFit: 'cover', borderRadius: '4px' }} />
                    ))}
                </Carousel>

                <div style={{ display: 'flex', gap: '10px' }}>
                    <p><strong>Vârstă:</strong> {profile.age}</p>
                    <p><strong>Sex:</strong> {profile.sex}</p>
                    <p><strong>Caută:</strong> {profile.looking}</p>
                </div>

                <p><strong>Locatie:</strong> {profile.location}</p>

                <div style={{ display: 'flex', gap: '10px' }}>
                    <p><strong>Știe partenerul:</strong> {profile.partner_knows ? 'Da' : 'Nu'}</p>
                    <p><strong>Aprobă partenerul:</strong> {profile.partner_approves ? 'Da' : 'Nu'}</p>
                    <p><strong>Nivel de secret:</strong> {secrecyMap(profile.secrecy_level)}</p>
                </div>

                <p><strong>Casă disponibilă:</strong> {profile.house_available ? 'Da' : 'Nu'}</p>

                <p><strong>Descriere:</strong> {profile.description}</p>
                
                <p><strong>Priorități:</strong> {profile.priority.join(', ')}</p>


              </div>
            )
        }
    }

    useEffect(() => {
        setLoading(true);
        getProfile(getId())
            .then((result) => {
                setCheater(result); // Set the data to state when the promise resolves
                setLoading(false);
            })
            .catch((err) => {
                setError(err); // Handle errors
                setLoading(false);
            });
    }, []);

    return (
        <div className="container">
            {renderError()}
            {renderLoading()}
            {renderProfile()}
        </div>
    )
}

export default Profile;